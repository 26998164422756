.checkboxes-filter-section {
    margin-top: 10px;
    float: none !important;
}

.checkboxes-filter-section label {
    display: inline;
}

.search-button {
    background-color: #1a161e !important;
    color: #ffffff !important;
}

.search-button:hover {
    background-color: #ff708a !important;
}


.search-filter-heading {
    position: initial !important;
}

.num {
    float: right;
}

.checkout-form .check-box:last-child {
    margin-right: 70px !important
}

.filter-link-wrapper {
    border: 2px solid #95d3c7;
    display: flex;
    align-items: center;
    padding: 10px;
}

.checkout-form .check-box:last-child {
    margin-right: 0 !important;
}

.sidebar .sidebar-title:before {
    background-color: white;
}

